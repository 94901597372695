<script setup>
import { defineAsyncComponent } from 'vue';
import HawkLoader from '~/common/components/atoms/hawk-loader.vue';

const loading_transmittals = ref(true);
const loading_documents = ref(true);

const DmsCustomFieldsTable = defineAsyncComponent({
  loader: () => import('~/dms/components/settings/dms-custom-fields-table.vue'),
  loadingComponent: HawkLoader,
});

const loading = computed(() => (loading_transmittals.value || loading_documents.value));
</script>

<template>
  <HawkLoader v-if="loading" />
  <div v-show="!loading">
    <DmsCustomFieldsTable :heading="$t('Transmittals')" :sub_heading="$t('Personalize your transmittal information with custom fields')" allowed_in="transmittals" @loaded="loading_transmittals = false" />
    <DmsCustomFieldsTable class="mt-8" :heading="$t('Documents')" :sub_heading="$t('Personalize your document information with custom fields')" allowed_in="documents" @loaded="loading_documents = false" />
  </div>
</template>
