import { defineAsyncComponent } from 'vue';
import HawkLoader from '~/common/components/atoms/hawk-loader.vue';
import Files from '~/dms/pages/files.vue';

import DMSCustomFields from '~/dms/pages/settings/dms-custom-fields.vue';
import CreateTransmittals from '~/dms/pages/transmittals/create-transmittals.vue';
// ------------- Transmittals -------------------------------
import DmsTransmittals from '~/dms/pages/transmittals/dms-transmittals.vue';
import TransmittalDetailPage from '~/dms/pages/transmittals/transmittal-detail-page.vue';

import TransmittalDetailSettings from '~/dms/pages/transmittals/transmittal-detail-settings.vue';
import TransmittalPublicPage from '~/dms/pages/transmittals/transmittal-public-page.vue';
// ------------- Settings ---------------------------------
import Settings from '~/dms/pages/settings/settings.vue';
import TransmittalSettings from '~/dms/pages/settings/transmittal-settings.vue';
import WorkflowTemplates from '~/dms/pages/settings/workflow-templates.vue';

const Documents = defineAsyncComponent({
  loader: () => import('~/dms/pages/documents/documents.vue'),
  loadingComponent: HawkLoader,
});
const CustomStatus = defineAsyncComponent({
  loader: () => import('~/dms/pages/settings/custom-status.vue'),
  loadingComponent: HawkLoader,
});
const DocumentAllFiles = defineAsyncComponent({
  loader: () => import('~/dms/pages/documents/document-all-files.vue'),
  loadingComponent: HawkLoader,
});
const DocumentShared = defineAsyncComponent({
  loader: () => import('~/dms/pages/documents/document-shared.vue'),
  loadingComponent: HawkLoader,
});
const DocumentArchived = defineAsyncComponent({
  loader: () => import('~/dms/pages/documents/document-archived.vue'),
  loadingComponent: HawkLoader,
});
const DocumentTrash = defineAsyncComponent({
  loader: () => import('~/dms/pages/documents/document-trash.vue'),
  loadingComponent: HawkLoader,
});
const DocumentAddMarkups = defineAsyncComponent({
  loader: () => import('~/dms/pages/documents/document-add-markups.vue'),
  loadingComponent: HawkLoader,
});

const routes = [
  {
    path: '/:asset_id?/dms',
    name: 'dms',
    component: Files,
    meta: {
      title: 'Files',

    },
    redirect: { name: 'files-documents' },
    children: [
      {
        path: 'documents',
        component: Documents,
        name: 'files-documents',
        redirect: { name: 'files-documents-all-files' },
        meta: {
          module: 'dms',

        },
        children: [{
          path: 'all',
          component: DocumentAllFiles,
          name: 'files-documents-all-files',
          meta: {
            page: 'files',
          },
          props: route => ({ asset_id: route.params.asset_id }),
        }, {
          path: 'shared',
          component: DocumentShared,
          name: 'files-documents-shared',
          meta: {
            page: 'shared',
          },
          props: route => ({ asset_id: route.params.asset_id }),
        }, {
          path: 'archived',
          component: DocumentArchived,
          name: 'files-documents-archived',
          meta: {
            page: 'archive',
          },
          props: route => ({ asset_id: route.params.asset_id }),
        }, {
          path: 'trash',
          component: DocumentTrash,
          name: 'files-documents-trash',
          meta: {
            page: 'trash',
          },
          props: route => ({ asset_id: route.params.asset_id }),
        }],
      },
      {
        path: 'transmittals',
        component: DmsTransmittals,
        name: 'files-transmittals',
        meta: {
          module: 'dms',

        },
      },
      {
        path: 'settings',
        component: Settings,
        name: 'dms-settings',
        redirect: { name: 'dms-custom-status' },
        meta: {
          module: 'dms',

        },
        children: [
          {
            path: 'custom-status',
            component: CustomStatus,
            name: 'dms-custom-status',
            meta: {
              module: 'dms',

            },
          },
          {
            path: 'custom-fields',
            component: DMSCustomFields,
            name: 'dms-custom-fields',
            meta: {
              module: 'dms',

            },
          },
          {
            path: 'transmittal-settings',
            component: TransmittalSettings,
            name: 'dms-transmittal-settings',
            meta: {
              module: 'dms',

            },
          },
          {
            path: 'workflow-templates',
            component: WorkflowTemplates,
            name: 'dms-workflow-templates',
            meta: {
              module: 'dms',

            },
          },
        ],
      },
    ],
  },
  {
    path: '/:asset_id?/transmittals/:transmittal_uid',
    component: TransmittalDetailPage,
    name: 'files-transmittals-detail',
    meta: {
      module: 'dms',

    },
    children: [
      {
        path: 'settings',
        name: 'transmittal-detail-settings',
        component: TransmittalDetailSettings,
        meta: {
          module: 'dms',

        },
      },
      {
        path: 'document-markup/:document_uid',
        name: 'document-markup',
        component: DocumentAddMarkups,
        meta: {
          module: 'dms',

        },
      },
    ],
  },
  {
    path: '/:asset_id?/transmittals-create',
    component: CreateTransmittals,
    name: 'files-create-transmittals',
    meta: {
      module: 'dms',

    },
  },
  {
    path: '/transmittals/:transmittal_uid/public',
    component: TransmittalPublicPage,
    name: 'public-transmittals',
    meta: {
      module: 'dms',
      layout: 'public',
      noAuthRequires: true,
    },
  },
];
export default routes;
