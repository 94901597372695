<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { defineAsyncComponent } from 'vue';
import HawkLoader from '~/common/components/atoms/hawk-loader.vue';

import { useTransmittalActions } from '~/dms/composables/transmittal-actions.composable.js';
// --------------------------------- Imports -------------------------------- //
import { useTransmittalsStore } from '~/dms/store/transmittals.store.js';
// ---------------------------------- Props --------------------------------- //

const props = defineProps({
  active_tab: {
    type: String,
  },
  document_details: {
    type: Object,
  },
  show_menu: {
    type: Boolean,
    default: true,
  },
  show_close: {
    type: Boolean,
    default: true,
  },
});
// ---------------------------------- Emits --------------------------------- //

const emit = defineEmits(['close', 'activities']);
const DocumentContextMenu = defineAsyncComponent({
  loader: () => import('~/dms/components/documents/table/document-context-menu.vue'),
  loadingComponent: HawkLoader,
});
const $t = inject('$t');
// ---------------------------- Injects/Provides ---------------------------- //

// ----------------------- Variables - Pinia - consts ----------------------- //
const transmittals_store = useTransmittalsStore();

// --------------------- Variables - Pinia - storeToRefs -------------------- //

// ------------------- Variables - Local - consts and lets ------------------ //

// ------------------------ Variables - Local - refs ------------------------ //

const state = reactive({
  show_viewer: false,
});
const { dropdown_items } = useTransmittalActions(state);

// ---------------------- Variables - Local - reactives --------------------- //

// --------------------------- Computed properties -------------------------- //
const document_status_map = computed(() => transmittals_store.transmittal?.review_status_set);

// -------------------------------- Functions ------------------------------- //

// -------------------------------- Watchers -------------------------------- //

// ----------------------------- Lifecycle Hooks ---------------------------- //

const dropdown_items_with_activities = computed(() => {
  return [
    props.active_tab === 'activities'
      ? {
          label: $t('View Details'),
          uid: 'can_view_details',
          permission: true,
          on_click: () => emit('details'),
        }
      : {
          label: $t('View activity log'),
          uid: 'can_view_activities',
          permission: true,
          on_click: () => emit('activities'),
        },
    ...dropdown_items(props.document_details),
  ];
});
</script>

<template>
  <div class="flex mt-6  pl-6 pr-4">
    <div class="flex justify-between w-full">
      <div class="text-xs">
        <div class="text-xl font-semibold text-gray-900">
          {{ document_details?.name }}   <TransmittalReviewStatus :document="document_details" :document_status_map="document_status_map" />
        </div>
        <div v-if="document_details?.number" class="text-xs text-gray-600 pt-1 mr-2">
          {{ `${$t('Document No')}: ${document_details?.number}` }}
        </div>
      </div>
    </div>
    <div class="flex gap-2">
      <div v-if="!!show_menu" class="text-gray-500">
        <DocumentContextMenu
          class="h-max"
          :items="dropdown_items_with_activities"
        >
          <template #trigger>
            <HawkButton type="plain">
              <IconHawkDotsVertical class="h-5 w-5" />
            </HawkButton>
          </template>
        </DocumentContextMenu>
      </div>
      <div v-if="!!show_close" class="text-gray-500">
        <HawkButton
          type="plain"
          @click="emit('close')"
        >
          <IconHawkX
            class="cursor-pointer text-base hover:text-gray-600 outline-none h-max"
            tabindex="0"
          />
        </HawkButton>
      </div>
    </div>
  </div>
</template>
